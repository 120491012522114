import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import "../css/Nav.css";

export default function Nav() {
    const [expanded, setExpanded] = useState(false);
    const [d, setD] = useState(false);
    const location = useLocation();
    const { i18n ,t} = useTranslation();
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.navbar');
            if (navbar) {
                if (window.scrollY > 100) {
                    navbar.classList.add('navbar-scrolled');
                } else {
                    navbar.classList.remove('navbar-scrolled');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <nav className="navbar navbar-expand-lg px-5 p-0">
            <div className="container p-0">
                <Link className="navbar-brand logo-background1" to={'/'}>
                   
                        <img src="/images/logo.png" alt="logo" />
                          
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setExpanded(!expanded)}
                >
                    <span className="navbar-toggler-icon">|||</span>
                </button>

                <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav ml-auto nav-item-background ">
                        {/* <li className="nav-item dropdown"> */}
                            {/* <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                {t('nav.more')}<span ></span>
                            </a>
                            <div className="dropdown-menu">
          
                                <Link to={'/about-us'} className="dropdown-item">{t('nav.aboutUs')}</Link>
                                <Link to={'/faqs'} className="dropdown-item">{t('nav.faq')}</Link>
                            </div> */}
                            <li className="nav-item">
                                     <Link to={'/about-us'} className="nav-link">{t('nav.aboutUs')}</Link>
                            </li>
                        {/* </li> */}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                        {t('nav.language')}<span ></span>
                            </a>
                            <div className="dropdown-menu" style={{ width: "100%" }}>
                                <a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage('en')}>English</a>
                                <a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage('hi')}>हिन्दी</a>
                            </div>
                        </li>
                        <li className="nav-item"><Link to={'/login'} className={location.pathname == '/login' ? 'nav-link active' : 'nav-link'}>{t('nav.login')}</Link></li>
                    </ul>

                    <div className="user_icon">
                        <a data-toggle="modal" data-target="#search-modal" href="#">
                            <i className="icofont-search"></i>
                        </a>
                       

                        {/* <div className="user">
                            <div className="nav-item dropdown">
                                <img src={profile.profilePic} width={30} height={30} alt="" />
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                   
                                </a>
                                <div className="dropdown-menu">
                                    <Link to='/update-password' className="dropdown-item">
                                        <i className="fa fa-user-o"></i>{t('nav.updatePassword')}
                                    </Link>
                                    <a href="#" className="dropdown-item" onClick={logout}>
                                        <i className="fa fa-power-off"></i> {t('nav.logout')}
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </nav>
    );
}

