import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Context from "../Context";
import Layout from "../Layout/Layout";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import firstimg from '../Images/1.png';
import bannerImage from '../Images/banner1.png';
import div2img from '../Images/div2img.png';
import { getTokenDetails } from "./utilities/CommonFunction";
// library for popup
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../src/css/style.css";
import "../css/Home.css";

const Axios = require("axios");
const cookie = require("js-cookie");

const Constant = require("../../src/views/Constant");

const options = {
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

const today = moment(new Date()).format("YYYY-MM-DD");

export default function Home() {
  const [bannerList, setBannerList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [storyList, setStoryList] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const [moviesList, setMoviesList] = useState([]);
  const [poemList, setPoemList] = useState([]);
  const [contestList, setContestList] = useState([]);
  const [isLodding, setIsLodding] = useState(true);
  const history = useHistory();
  const [getClipboard, setClipboard] = useState("");
  const [getEventList, setEventList] = useState([]);
  const { state, dispatch } = useContext(Context);
  const [audioAccess, setAudioAccess] = useState(0);
  const [videoAccess, setVideoAccess] = useState(0);
  const [userId, setUserId] = useState(null);
  // popup state
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const owlRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    let metaData = {};
    let token = sessionStorage.getItem("loginDetails");
    if (token) {
      let userDetails = getTokenDetails(token);
      if (userDetails) {
        metaData.userId = userDetails._id;
        setUserId(userDetails._id)
      }
    }
    let response = await Axios.post(
      Constant.apiBasePath + "home/pageData",
      metaData,
      { headers: { token: token } }
    );
    let data = response.data;
    if (data.status === Constant.statusSuccess) {
      let {
        bannerList,
        videoList,
        storyList,
        moviesList,
        audioList,
        poemList,
        contestList,
        eventList,
        audioAccess,
        videoAccess,
      } = data.data;
      setBannerList(bannerList);
      setVideoList(videoList);
      setStoryList(storyList);
      setMoviesList(moviesList);
      setAudioList(audioList);
      setPoemList(poemList);
      setContestList(contestList);
      setEventList(eventList);
      setAudioAccess(audioAccess);
      setVideoAccess(videoAccess);
      setIsLodding(false);
    }

    // verify cookies
    if (!cookie.get("user_auth")) {
      window.$("#cookiesModal").modal("show");
    }
 
  }, []);
  const options = {
    items: 1,
    nav: true,
    rewind: true,
    dots: false,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
  };

  function redirectOnCreateStory() {
    window.$("#startwritingModal").modal("hide");
    history.push("/story-create");
  }

  function redirectOnCreatePoem() {
    window.$("#startwritingModal").modal("hide");
    history.push("/poem-create");
  }

  function redirectOnReadingStory() {
    window.$("#startreadingModal").modal("hide");
    history.push("/stories");
  }

  function redirectOnReadingPoem() {
    window.$("#startreadingModal").modal("hide");
    history.push("/poems");
  }

  function copyClipBoard(url) {
    let textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      setClipboard("Link copied to clipboard");
      setTimeout(function () {
        setClipboard("");
      }, 3000);
    } catch (err) {
      setClipboard("Unable to copy to clipboard", err);
      setTimeout(function () {
        setClipboard("");
      }, 3000);
    }
    document.body.removeChild(textArea);
  }

  function saveCookies() {
    let token = sessionStorage.getItem("loginDetails");
    cookie.set("user_auth", token, { sameSite: "strict" });
    window.$("#cookiesModal").modal("hide");
  }

  function closeModelAndRedirect() {
    window.$("#homeSubscriptionPlanModal").modal("hide");
    history.push("/plans");
  }


  const NextArrow = (props) => {
    const { onClick } = props;
    return <div className="arrow next" onClick={onClick}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="32" viewBox="0 0 25 32" fill="none">
    <path d="M24.0647 16.1154L0.12119 0.440401L0.0086192 31.6171L24.0647 16.1154Z" fill="#D9D9D9"/>
    </svg></div>;
  };
  
  const PrevArrow = (props) => {
    const { onClick } = props;
    return <div className="arrow prev" onClick={onClick}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="32" viewBox="0 0 25 32" fill="none">
    <path d="M0.0650119 16.1155L24.0086 0.440462L24.1211 31.6172L0.0650119 16.1155Z" fill="#D9D9D9"/>
  </svg></div>;
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <Layout>
      {/* Start Banner Section */}

      <section className="banner_section1" style={{ backgroundImage: `url(${bannerImage})` }}>
  <div className="container d-flex align-items-center "  style={{ height: "100%", width: "100%" }}>
    <div className="order_2 b-div">
      <div className="slider_info">
        <div className="">
          <p className="banner-text">
            {t('home.storyThought')}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


      {/* End Banner Section */}

      {/* Start Event Section */}
<div className="second-sectionnew">
   
    <div className="container p-4" >
       
        <Slider {...settings}>
             
           
            
             {getEventList.map((item, index) => <div className="inner-div-secondsection" > <div className="upcoming-event1 p-2"> <div className="item" key={item._id}>
                                      <div className="row align-items-center ">
                                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center">
                                              <div className="eventleftbox1 d-flex justify-content-center">
                                                  <img src="images/megaphone-img.png"></img>
                                              </div>
                                              
                                          </div>
                                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center">
                                     
                                          <p className="event-p">Events</p>
                                          <div>
                                          <h1 className="h1-div">{item.name}</h1>
                                          </div>
                                          <div className="creative_box1 d-flex justify-content-between">
  <div className="date-and-venue">
    <div className="date-div">{item.date.split('T')[0]}</div>
    <div className="venue-div">{item.venue}</div>
  </div>
</div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center">
                                              <div className="">
                                              <Link to={`/TicketBooking/${item._id}`}> <button className=" extended-btn "> Book Now</button></Link>
                                              </div>
                                              
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                                  </div>
                              )}
             </Slider>
        </div>
    </div>

   
        {/* End Testomonial Section */}
    
    <div className="second-sectionnew">
   
    <div className="container p-4" >
   <center><p className="event-p">Contests</p></center>  
        <Slider {...settings}>
             
           
        {contestList.map((item, index) =><div className="inner-div-secondsection" ><div className="upcoming-event1" key={item._id}>
                                                        <div className="">
                                                            <div className="">
                                                                <div >
                                                                    <center>
                                                                   <p className="event-p">{item.name}</p> 
                                                                   {/* Contest Id: {item._id} */}
                                                                   </center>
                                                                </div>
                                                              
                                                            </div>
                                                            <div className="">
                                                                <img src={item.image} alt={`img_${item._id}`} />
                                                            </div>
                                                            <div className="button_box d-flex justify-content-center align-items-center">
                                                                
                                                                {/* Participation in story or poem 
                                                                    View contest of users
                                                                    View result of contest
                                                                */}
                                                                
                                                                {
                                                                    (item.startDate <= today && item.closureDate >= today)
                                                                    ?
                                                                        <>
                                                                            <Link params={{contest_id:item._id, userId: userId }} className="extended-btn" to={{pathname:`/contest-story`, search:`?contest_id=${item._id}&userId=${userId}` }} >Participate in Story</Link>
                                                                        </>
                                                                    :
                                                                    (item.closureDate < today && item.resultDate > today)
                                                                    ?
                                                                        <>
                                                                        <Link className="extended-btn" to="/contest/1">View Story Contests</Link>
                                                                        </>
                                                                    :
                                                                    (item.resultDate <= today) 
                                                                    ?
                                                                        <>
                                                                           <Link className="extended-btn" to={`/contest-result/${item._id}/1`}>View Story Result</Link>
                                                                        </>
                                                                    :
                                                                        ""
                                                                }
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                )}
             </Slider>
        </div>
    </div>

        <div class="modal fade" id="homeReirectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content startPopup">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel1">Authentication required.</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Please login to access detail mode</p>
                        <div class="theme-button">
                            <Link to="/login" class="default-btn btn-sm">Login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Please login to see details view
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <div class="theme-button">
          <Link to="/login" class="default-btn"> Login </Link>
          </div>
        </DialogActions>
      </Dialog>

        {/* End modal */}

        </Layout>
    )
}