import "../../css/Package.css";

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Context from "../../Context";
import Layout from "../../Layout/Layout";
import Constant from "../Constant";
import { createPaymentRequest, createUserSubscription, getEnrollmentDataForApp, getUserPlan, updatePaymentDetails } from "../utilities/ApiCalls";

function Package(props) {
    const history = useHistory();
    const [plans, setPlans] = useState([]);
    const [userPlans, setUserPlans] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const {state, dispatch} = useContext(Context);
    const {t} = useTranslation();

    const query = new URLSearchParams(props.location.search);
    let payment_id = query.get('payment_id');
    let payment_status = query.get('payment_status');
    let payment_request_id = query.get('payment_request_id');

    useEffect(() => {
        // getProfileDetails().then((response) => {
        //     setUserDetails(response.data);
        // });

        getUserPlan().then((res) => {
            setUserPlans(res.data);
        })

        if(state.user) {
            // console.log({userId: state.user});
            // save user info in state
            getEnrollmentDataForApp({userId: state.user._id}).then((response) => {
                setPlans(response.data);
                setUserDetails(state.user);
            });
        }

        // razorpay payment gateway initialization

        // const script = document.createElement("script");
        // script.src = "https://checkout.razorpay.com/v1/checkout.js";
        // script.async = true;
        // document.body.appendChild(script);

        // instamojo payment gateway update status
        // update payment details in db

        if(payment_id && payment_status && payment_request_id) {
            let paymentRequest = {
                "orderId": payment_request_id,
                "paymentId": payment_id,
                "paymentStatus": 2,
                "isPrime": 1
            }
            updatePaymentDetails(paymentRequest).then(response => {
                if(response.status === Constant.statusSuccess) {
                    setSuccessMessage(response.message);
                    setTimeout(function() {
                        history.push(`/`);
                    }, 3000);
                }
                else {
                    setErrorMessage(response.message);
                }
            }).catch(error => {
                console.log(error.response.data.message);
            })
        }


    }, []);

    function updateTypeBackup(e) {
        e.preventDefault();
        let plan_id = e.target.getAttribute('value');
        let currentplan = plans.find(item => item._id == plan_id);
        if(currentplan) {
            if(parseInt(currentplan.amount) > 0) {

                let token = sessionStorage.getItem('loginDetails');
                var amount = currentplan.amount; //Razorpay consider the amount in paise
                var options = {
                    "key": 'rzp_test_a5bjEyydEthrBk',
                    "amount": "", // 2000 paise = INR 20, amount in paisa
                    "name": "",
                    "description": "",
                    'order_id':"",
                    "handler": function(response) {
                        var values = {
                            enrollmentId: currentplan._id,
                            razorpaySignature : response.razorpay_signature,
                            razorpayOrderId : response.razorpay_order_id,
                            razorpayPaymentId : response.razorpay_payment_id,
                            secret: 'ZPzYKhzrveCXWqIgT8pmNu3j',
                            finalAmount : amount,
                            expiredDate: currentplan.expiredDate,
                            isPrime: currentplan.isPrime
                        }
                        axios.post(Constant.apiBasePath + 'order/verify-signature', values, { headers: { 'token': token }})
                        .then( res => { 
                            setSuccessMessage(res.data.message); 
                            setTimeout(function() { history.push('/') }, 3000);
                        })
                        .catch(e => console.log(e) );
                    },
                    "prefill":{
                        "name": userDetails.name,
                        "email": userDetails.email,
                        "contact": userDetails.mobile
                    },
                    "notes": {
                        "address": "Hello World"
                    },
                    "theme": {
                        "color": "#528ff0"
                    }
                };
                let metaData = {
                    "amount": amount,
                    "enrollmentId": currentplan._id,
                    "isPrime": 1,
                    "expiredDate": currentplan.expiredDate
                }
    
                axios.post(Constant.apiBasePath + 'order/create', metaData, { headers: { 'token': token }})
                    .then(res=>{
                        if(res.data.status === Constant.statusSuccess) {
                            options.order_id = res.data.data.razorpayOrderId;
                            options.amount = res.data.data.amount;
                            var rzp1 = new window.Razorpay(options);
                            rzp1.open();
                        }
                        else {
                            alert(res.data.message);
                            return false;
                        }
                    })
                    .catch(e => console.log(e))
            
            }
            else{
                createUserSubscription({
                    enrollmentId: currentplan._id,
                    amount: currentplan.amount,
                    isPrime: currentplan.isPrime,
                    expiredDate:currentplan.expiredDate
                }).then((response) => {
                    if (response.status == Constant.statusSuccess) {
                        setSuccessMessage(response.message);
                        setTimeout(function() { history.push('/') }, 3000);
                    } else {
                        setErrorMessage(response.message);
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
        else {
            setErrorMessage('No current plan found!');
        }
    }

    function updateType(e) {
        e.preventDefault();
        let plan_id = e.target.getAttribute('value');
        let currentplan = plans.find(item => item._id == plan_id);
        if(currentplan) {
            if(parseInt(currentplan.amount) > 0) {

                let paymentRequest = {
                    "amount": parseInt(currentplan.amount),
                    "purpose": "Membership plan",
                    "redirectURL": `${Constant.domain}` + `plans`,
                    "isPrime": 1,
                    "paymentFor": 1,
                    "enrollmentId": currentplan._id
                }
                createPaymentRequest(paymentRequest).then(orderResponse => {
                    if(orderResponse.status === Constant.statusSuccess) {
                        window.location.href = orderResponse.data.longurl;
                    }
                    else {
                        setErrorMessage(orderResponse.message);
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                })            
            }
            else{
                createUserSubscription({
                    enrollmentId: currentplan._id,
                    amount: currentplan.amount,
                    isPrime: currentplan.isPrime,
                    expiredDate:currentplan.expiredDate
                }).then((response) => {
                    if (response.status == Constant.statusSuccess) {
                        setSuccessMessage(response.message);
                        setTimeout(function() { history.push('/') }, 3000);
                    } else {
                        setErrorMessage(response.message);
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
        else {
            setErrorMessage('No current plan found!');
        }
    }
console.log(plans);

    return ( 
        <Layout>
        <div style={{background: '#07262B', minHeight: '100vh'}}>
            <div className="container flex flex-col text-center" style={{padding: '20px'}}>
                <div className="row margin-right-zero mt-5">
                      {/* <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                        <div className="heading_title mt-30">
                            <h1>{t('plans.plans')}</h1>
                            { (userPlans.length < 1) ? <p>{t('plans.youAreUnsubscribeUser')}</p> : '' }
                            {
                                (successMessage)
                                ?
                                <p className="text-success success-msg-text">{ successMessage }</p> 
                                :
                                ''
                            }

                            {
                                (errorMessage)
                                ?
                                <p className="text-danger error-msg-text">{ errorMessage }</p>
                                :
                                ''
                            }
                        </div>
                    </div> */}
                    <h4 className="main-header">Choose Your Plan</h4>
                </div>
                <div className="row margin-right-zero">
                    <h6 className="second-header">Unlock Endless Possibilities</h6>
                </div>
                <div className="row margin-right-zero" style={{marginTop: '30px'}}>
                {    plans.map((plan, index) => {
               return <div className="col-md-6 col-sm-12 text-center">
                        <div className="package-card row margin-right-zero flex flex-col text-center p-2">
                            <div className="row">
                                <h4 className="package-header">{plan.name} </h4>
                            </div>
                            <div className="row">
                            <span className="package-description" dangerouslySetInnerHTML={{ __html: plan.description }} />
                            </div>
                            <div className="row mt-2">
                                <span className="package-price-symbol">Rs</span><span className="package-price">{plan.amount}</span>
                            </div>
                            <div className="row">
                            <button type="submit" className="package-button" onClick={updateType}
                                                        value={plan._id} disabled={ (plan.userPlan === true) ? 'disabled': '' }>{t('plans.purchaseNow')}
                                                </button>
                                
                            </div>
                        </div>
                    </div>
                
               })} </div>
            </div>
        </div>
        </Layout>
     );
}

export default Package;

