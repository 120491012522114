// import package and components

import React, { useEffect, useReducer } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import Context from "./Context";
import UserAccess from './UserAccess';
import AboutUS from './views/AboutUS';
import AudioDetails from './views/AudioDetails';
import Audios from "./views/Audios";
import Booking from './views/Booking/Booking';
import { statusFailure } from "./views/Constant";
import Contest from './views/Contest';
import ContestResult from './views/ContestResult';
import ContestStory from './views/ContestStory';
import CookiesPolicy from './views/CookiesPolicy';
import EventPurchasePage from './views/EventPurchase';
import FAQ from './views/FAQ';
import Forgot from "./views/Forgot";
import Home from "./views/Home";
import Login from './views/Login';
import Movies from "./views/Movies";
import MoviesDetails from './views/MoviesDetails';
import OurTeam from "./views/OurTeam";
import OurVision from "./views/OurVision";
import Package from './views/Package/Package';
import PoemCreate from "./views/Poem/PoemCreate";
import PoemDetails from "./views/PoemDetails";
import Poems from "./views/Poems";
import PrivacyPolicy from './views/PrivacyPolicy';
import Profile from "./views/Profile";
import Register from "./views/Register";
import Stories from "./views/Stories";
import StoryCreate from "./views/Story/StoryCreate";
import StroyDetailsNew from './views/Story/StoryDetailsNew';
import StoryWrite from './views/Story/StoyWrite';
import TermCondition from './views/TermCondition';
import Thumbnail from './views/Thumbnail';
import UpdatePassword from "./views/UpdatePassword";
import UpdateUserProfile from './views/UpdateUserProfile';
import UserType from "./views/UserType";
import VideoDetails from './views/VideoDetails';
import Videos from "./views/Videos";
import WhatWeDo from "./views/WhatWeDo";
import WhoWeAre from "./views/WhoWeAre";
import Payment from './views/payment';
import { getProfileDetails } from "./views/utilities/ApiCalls";

/* End */

function reducer(state, {payload, type}) {
    switch (type) {
        case 'login':
            sessionStorage.setItem('loginDetails', payload);
            return {
                ...state,
                token: payload
            };
        case 'logout':
            sessionStorage.removeItem('loginDetails');
            
            return {
                ...state,
                token: null,
                user:{},
                isPrime:false
            };
        case 'user':
            return {
                ...state,
                user: payload
            }
        case 'isPrime':
            return {
                ...state,
                isPrime: !!payload
            }
        default:
            return state;
    }
}


export  default  function App (){
    let token = sessionStorage.getItem('loginDetails');

    const [state, dispatch] = useReducer(reducer, {
        token,
        user:{},
        isPrime:false,
    } );

    useEffect(() => {
        if (!state.token) return
        getProfileDetails().then((response) => {
            console.log('response',response.data)
            if (response.status !== statusFailure) {
                dispatch({type: 'user', payload: response.data});
            } else {
                dispatch({type: 'user', payload: {}});
                console.log(response.message);
            }
        })
    }, []);


    useEffect(() => {
         if (state.isPrime ) {
             dispatch({type: 'isPrime', payload: true});
         }else{
             dispatch({type: 'isPrime', payload: false});
         }
    }, [state.user]);

    return (
      <Context.Provider value={{ state, dispatch }}>
        <Router>
          <Switch>
            <Route exact strict path="/" component={Home} />
            <Route exact strict path="/about-us" component={AboutUS} />
            <Route exact strict path="/who-we-are" component={WhoWeAre} />
            <Route exact strict path="/what-we-do" component={WhatWeDo} />
            <Route exact strict path="/our-vision" component={OurVision} />
            <Route exact strict path="/our-partner" component={OurTeam} />
            <Route exact strict path="/faqs" component={FAQ} />
            <Route
              exact
              strict
              path="/contest-story"
              component={ContestStory}
            />
            <Route exact strict path="/register" component={Register} />
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/forgot" component={Forgot} />
            <Route exact strict path="/" component={Forgot} />
            <Route exact strict path="/plans" component={Package} />
            <Route exact strict path="/TicketBooking/:id" component={Booking} />
            <Route
              exact
              strict
              path="/term-condition"
              component={TermCondition}
            />
            <Route exact strict path="/stories" component={Stories} />
            <Route
              exact
              strict
              path="/story-details/:id"
              component={StroyDetailsNew}
            />
            <Route exact strict path="/videos" component={Videos} />
            <Route exact strict path="/movies" component={Movies} />
            <Route exact strict path="/audios" component={Audios} />
            <Route exact strict path="/story-create" component={StoryWrite} />
            <Route exact strict path="/update-story" component={StoryCreate} />
            <Route exact strict path="/poems" component={Poems} />
            <Route
              exact
              strict
              path="/poem-details/:id"
              component={PoemDetails}
            />
            <Route exact strict path="/poem-create" component={PoemCreate} />
            <Route exact strict path="/update-poem" component={PoemCreate} />

            <Route exact strict path="/user-type" component={UserType} />
            <Route
              exact
              strict
              path="/update-password"
              component={UpdatePassword}
            />
            <Route exact strict path="/profile/:userId" component={Profile} />

            <Route exact strict path="/location" component={Location} />
            <Route
              exact
              strict
              path="/update-profile"
              component={UpdateUserProfile}
            />

            <Route
              exact
              strict
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route
              exact
              strict
              path="/cookies-policy"
              component={CookiesPolicy}
            />
            <Route
              exact
              strict
              path="/video-details/:id"
              component={VideoDetails}
            />
            <Route
              exact
              strict
              path="/movies-details/:id"
              component={MoviesDetails}
            />
            <Route
              exact
              strict
              path="/audio-details/:id"
              component={AudioDetails}
            />
            <Route exact strict path="/contest/:type" component={Contest} />
            <Route
              exact
              strict
              path="/select-thumbnail/:type"
              component={Thumbnail}
            />
            <Route
              exact
              strict
              path="/contest-result/:id/:type"
              component={ContestResult}
            />
            <Route exact strict path="/online-payment" component={Payment} />
            <Route
              exact
              strict
              path="/event-payment"
              component={EventPurchasePage}
            />
            <Route exact strict path="/package" component={Package} />
            <Route exact strict path="/booking" component={Booking} />
            <Route exact strict path="/profile" component={Profile} />
            {/* <Route exact strict path="/story-write" component={StoryWrite} /> */}
            <Route
              exact
              strict
              path="/story-detailnew"
              component={StroyDetailsNew}
            />

            {/* <Route path="*" component={PageNotFound} /> */}
          </Switch>
        </Router>
      </Context.Provider>
    );

}
