import { useContext, useEffect, useState } from "react";
import SearchModalComponent from "../Componets/SearchModal";
import Context from "../Context";
import { getSiteSocial } from "../views/utilities/ApiCalls";
import Nav from "./Nav";
import NavAuthenticated from "./NavAuthenticated";
import { Link } from "react-router-dom";

export default function Layout(props) {
    const {state, dispatch} = useContext(Context);
    const [facebook, setFacebook] = useState(null);
    const [twitter, setTwitter] = useState(null);
    const [linkedin, setLinkedin] = useState(null);
    const [instagram, setInstagram] = useState(null);
    const [youtube, setYoutube] = useState(null);


    useEffect(() => {
        getSiteSocial().then((response) =>{
            response.data.map((item) => {
                if (item.title.toUpperCase() === "facebook".toUpperCase()) setFacebook(item)
                if (item.title.toUpperCase() === "twitter".toUpperCase()) setTwitter(item)
                if (item.title.toUpperCase() === "linkedin".toUpperCase()) setLinkedin(item)
                if (item.title.toUpperCase() === "instagram".toUpperCase()) setInstagram(item)
                if (item.title.toUpperCase() === "youtube".toUpperCase()) setYoutube(item)
            })
        })
    }, []);


    return (
      <>
        {state.token ? <NavAuthenticated /> : <Nav />}
        <div className="loader-content">
          <div className="d-table">
            <div className="d-table-cell">
              <div id="loading-center">
                <div id="loading-center-absolute">
                  <div className="object" id="object_one"></div>
                  <div className="object" id="object_two"></div>
                  <div className="object" id="object_three"></div>
                  <div className="object" id="object_four"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.children}

        <section className="" style={{ backgroundColor: "black" }}>
          <div className="container bg-transparent">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                <div className="footer_box text-center">
                  <ul className="flex align-items-center justify-content-center">
                    <li className=" ">
                      <Link to={"/term-condition"} className="text-light mr-2">
                        Terms And Conditions{" "}
                      </Link>
                    </li>

                    <li>
                      <Link to={"/privacy-policy"} className="text-light ml-2">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SearchModalComponent />
      </>
    );
}