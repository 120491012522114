const Axios = require("axios");
const Constant = require("../Constant");
const { getTokenDetails } = require('../utilities/CommonFunction');

export async function getBanners(data ={}) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'banner/get-all', data, {headers: {'token': token}});
    return response.data;
}


export async function getCountries() {
    let response = await Axios.get(Constant.apiBasePath + 'location/country/getAll');
    return response.data;
}

export async function getStatesbyCountry(prams={}) {

    let response = await Axios.get(Constant.apiBasePath + 'location/state/getAll', {
        params: prams
    } );
    return response.data;
}

export async function getCitiesbyState(params={}) {
    let response = await Axios.get(Constant.apiBasePath + 'location/city/getAll',{
        params:params
    });
    return response.data;
}
export async function getContests(params ={}) {
    let response = await Axios.get(Constant.apiBasePath + 'contest/getDataForApp',{
        params:params
    });
    return response.data;
}

export async function updateUserProfile(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.patch(Constant.apiBasePath + 'updateUserInfoById', data, {headers: {'token': token}});
    return response.data;
}

export async function updateUserPassword(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.patch(Constant.apiBasePath + 'updatePassword', data, {headers: {'token': token}});

    return response.data;
}

export async function getEnrollmentDataForApp(params) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.get(Constant.apiBasePath + 'enrollment/getDataForApp?userId=' + params.userId, {headers: {'token': token}});
    return response.data;
}

export async function getUserPlan() {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.get(Constant.apiBasePath + 'enrollment/getUserPlan', {headers: {'token': token}});
    return response.data;
}

export async function createUserSubscription(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'order/create-user-subscription', data, {headers: {'token': token}});
    return response.data;
}

export async function getStoryCategories(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');

    let response = await Axios.get(Constant.apiBasePath + 'category/getDataForApp', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function createStory(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'story/create', data, {headers: {'token': token}});
    return response.data;
}



export async function getThumbnailsFromAI(title,desc){
    console.log('getting from AI')
        const body = {
          steps: 40,
          width: 512,
          height: 512,
          seed: 0,
          cfg_scale: 5,
          samples: 1,
          style_preset: "digital-art",
          text_prompts: [
            {
              // Create a thumbnail for a story titled ${title}. The description is ${desc}
              text: `Create a thumbnail for a story titled ${title}. The description is ${desc}`,
              weight: 1,
            },
            {
              text: "blurry, bad",
              weight: -1,
            },
          ],
        };
    let resp = await Axios.post(
    "https://api.stability.ai/v1/generation/stable-diffusion-v1-6/text-to-image",

      JSON.stringify(body),
      {
        headers: {
          'Accept': "application/json",
          "Content-Type": "application/json",
          'Authorization':
            "Bearer sk-KMqoTytdE5LJzGmZChOWXAN2oGnKNqmLrdwZ2y7owkUDZ7Ur",
        },
      }
    );
       return [
         `data:image/jpeg;base64,${resp.data.artifacts[0].base64}`,
         resp.data.artifacts[0].base64,
       ];

}

export async function uploadStoryImage(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'story/uploadFileOnS3', data, {headers: {'token': token}});
    return response.data;
}
// export async function uploadThumbImage(data) {
//     let token = sessionStorage.getItem('loginDetails');
//     let formdata = new FormData()
//     var file = new File([data], 'story', { type: 'jpg' });
//     console.log(file)
//     formdata.append('story',file)
//     let response = await Axios.post(
//       Constant.apiBasePath + "story/uploadFileOnS3",
//       formdata,
//       { headers: { token: token } },
//     );
//     return response.data;
// }
function base64ToBlob(base64Data) {
  const byteCharacters = atob(base64Data);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteArray], { type: "image/jpeg" });
}
export async function uploadThumbImage(data) {
  try {
    let token = sessionStorage.getItem("loginDetails");
    let formdata = new FormData();

    const blob = base64ToBlob(data)

    formdata.append("story", blob, "story.jpg");

    let response = await Axios.post(
      Constant.apiBasePath + "story/uploadFileOnS3",
      formdata,
      { headers: { token: token } }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

export async function getStories(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        prams.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'story/getDataForApp', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function createPoem(data) {
    let token = sessionStorage.getItem('loginDetails');
    console.log(data)
    let response = await Axios.post(Constant.apiBasePath + 'poem/create', data, {headers: {'token': token}});
    return response.data;
}

export async function getPoems(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        prams.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'poem/getDataForApp', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function getProfileDetails(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.get(Constant.apiBasePath + 'user/getProfileDetails', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}


export async function userCoin(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'userCoin', data, {headers: {'token': token}});
    return response.data;
}


export async function getSiteSocial(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'getSocialMediaData', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function getPages(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');

    let response = await Axios.post(Constant.apiBasePath + 'searchChildCMSByTitle', prams, {
        headers: {'token': token}
    });
    return response.data;
}

export async function getVideos(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        prams.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'video/getDataForUser', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function getMovies(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        prams.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'movies/getDataForUser', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function getAudios(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        prams.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'audio/getDataForUser', {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function userLike(data) {
    let token = sessionStorage.getItem('loginDetails');

    let response = await Axios.post(Constant.apiBasePath + 'like', data, {
        headers: {'token': token},
    });
    return response.data;
}

export async function getNotification(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');

    let response = await Axios.get(Constant.apiBasePath + 'notification/getByUserId', {
        headers: { 'token': token },
        params: prams
    });
    return response.data;
}

export async function getThumbnail(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');

    let response = await Axios.get(Constant.apiBasePath + 'content-thumbnail/getDataForUser', {
        headers: { 'token': token },
        params: prams
    });
    return response.data;
}

export async function deleteStoryOrPoem(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    
    let type = '';
    if(prams.type === 1) {
        type = 'story';
    }
    else if(prams.type === 2) {
        type = 'poem';
    }

    let response = await Axios.delete(Constant.apiBasePath + type + '/user/' + prams.id, {
        headers: { 'token': token },
        params: prams
    });
    return response.data;
}

export async function getDataById(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    
    let type = '';
    if(prams.type == 1) {
        type = 'story';
    }
    else if(prams.type == 2) {
        type = 'poem';
    }

    let response = await Axios.get(Constant.apiBasePath + type + '/getDataById/' + prams.id, {
        headers: { 'token': token },
        params: prams
    });
    return response.data;
}

export async function getFollowOrFollowingList(metaData) {
    let token = sessionStorage.getItem('loginDetails');
    
    let response = await Axios.post(Constant.apiBasePath + 'getFollowOrFollowingList', metaData, { headers: { 'token': token }});
    return response.data;
}

export async function getUserBadge(params) {
    let token = sessionStorage.getItem('loginDetails');
    let metaData = {};
    let response = await Axios.post(Constant.apiBasePath + 'getUserBadge?type=' + params.type, metaData, { headers: { 'token': token }});
    return response.data;
}

export async function getTickets(params = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        params.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'event/tickets/' + params.user_id, {
        headers: {'token': token},
        params: params
    });
    return response.data;
}

export async function userFollow(data) {
    let token = sessionStorage.getItem('loginDetails');

    let response = await Axios.post(Constant.apiBasePath + 'follow-user', data, {
        headers: {'token': token},
    });
    return response.data;
}

export async function getContestResultList(prams = {}) {
    let token = sessionStorage.getItem('loginDetails');
    if(token) {
        let { _id } = getTokenDetails(token);
        prams.user_id = _id;
    }
    let response = await Axios.get(Constant.apiBasePath + 'contest/getContestResultById/' + prams.id, {
        headers: {'token': token},
        params: prams
    });
    return response.data;
}

export async function createPaymentRequest(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'order/createPaymentRequest', data, { headers: { 'token': token } });
    return response.data;
}

export async function updatePaymentDetails(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.post(Constant.apiBasePath + 'order/updatePaymentDetails', data, { headers: { 'token': token } });
    return response.data;
}

export async function createAppPaymentRequest(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.get(Constant.apiBasePath + 'order/createPaymentRequestForApp', { 
        headers: { 'token': token },
        params: data
    });
    return response.data;
}

export async function updateAppPaymentDetails(data) {
    let token = sessionStorage.getItem('loginDetails');
    let response = await Axios.get(Constant.apiBasePath + 'order/updatePaymentDetailsForApp', {
        headers: { 'token': token },
        params: data
    });
    return response.data;
}

export async function getAllContestData(fromPoem, body) {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZDM3NDA0Nzk0MDRhOTY1Mzk0OWJlOSIsIm5hbWUiOiJSYXZpbmRyYSBZYWRhdiIsImVtYWlsIjoiUmF2aW5kcmF5YWRhdjU0MzhAZ21haWwuY29tIiwicHJvZmlsZVBpYyI6IiIsInN0YXR1cyI6MCwicm9sZSI6MSwiaWF0IjoxNzA0NjA5NTU4LCJleHAiOjE3MjAxNjE1NTh9.v2dM4RsOymcsHEtBoyHIDS-GTYCJ6gn1Ws06OshPc1s";
    if (fromPoem) {
      const endUrl = Constant.apiBasePath + 'story/get-all'
      try {
        let metaInfo = await Axios.post(endUrl, body, {
          headers: { token: token },
        });
        return metaInfo;
      } catch (error) {
        alert("Error", "Server side issue");
        return error;
      }
    } else {
      const endUrl = Constant.apiBasePath + 'poem/get-all';
      try {
        let metaInfo = await Axios.post(endUrl, body, {
          headers: { token: token },
        });
        return metaInfo;
      } catch (error) {
        alert("Error", "Server side issue");
        return error;
      }
    }
  };