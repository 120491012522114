import Layout from "../Layout/Layout";
import {useEffect, useState} from "react";
import {getContestResultList} from "./utilities/ApiCalls";
import { statusFailure } from '../../src/views/Constant';
import {useParams} from "react-router";
import { Link } from 'react-router-dom';


export default function StoryDetails(props) {
    let { id, type } = useParams();

    const [dataList, setDataList] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorState, setErrorState] = useState(null);

    useEffect(() => {
        let prams = {
            id: id,
            type: type
        };
        
        getContestResultList(prams).then((response) => {
            if (response.status !== statusFailure && response.data.length > 0) {
                setDataList(response.data);                
            } else {
                setErrorState(response.message);
                setTimeout(function() {
                    setErrorState('');
                }, 3000);
            }
        })
    }, []);

    let contestResultList = [];
    if(dataList.length > 0) {
        contestResultList = dataList.map((el, index) => {

            return <li className="col-md-6 col-lg-6 col-12" key={el._id}>
                        <div className="row">
                            <div className="col-md-5 col-12">
                                <div className="contest_img">
                                    <img src={el.thumbnail} />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="contest_info">
                                    <div>
                                        <h4>{el.rank}<sup></sup></h4>
                                        <h2>Contest Name: <strong>{el.contestName}</strong></h2>
                                        <p>User Name: <strong>{el.userName}</strong></p>
                                        <p>Category Name: <strong>{ el.categoryName }</strong></p>
                                        <p>Likes: <strong>{ el.likes }</strong></p>
                                        <p>Type: <strong>{el.type}</strong></p>
                                        <p>Result Date: <strong>{ el.resultDate }</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

        });
    }

    return <>
        <Layout>
            <section className="video_gallery_section contest-detail">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="myTabContent">
                                <div className="videos_gallery">
                                    
                                    <ul className="row">
                                        { contestResultList }
                                    </ul>
                                    {successMessage && <div className="alert alert-success success-msg-text" role="alert"> {successMessage} </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    </>
}