// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './Translations/en.json';
import hiTranslations from './Translations/hi.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      hi: { translation: hiTranslations },
    },
    fallbackLng: 'en', // Default language
    debug: true, // Set to true to see console logs
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
