import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FcGoogle } from 'react-icons/fc';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Context from "../Context";
import bannerImage from '../Images/banner1.png';
import googleimg from '../Images/googleimg.png';
import '../css/Login.css';
import Constant, { statusFailure, statusSuccess } from "./Constant";
import { getCountries } from "./utilities/ApiCalls";
import { authFacebook, authGoogle, loginUser } from "./utilities/Authentication";





export default function Login() {
    const [formstate, setState] = useState({
        emailMobile: '',
        password: '',
        rememberMe: 0,
        countryCode: '91'
    });
    const history = useHistory();
    const {t} = useTranslation();
    const [checked, setChecked] = useState(false);
    const [privacyChecked, setprivacyChecked] = useState(false);

    const [errorState, setErrorState] = useState(null);

    const {state, dispatch} = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [allCountryCodes, setAllCountryCodes] = useState([]);


    function showHidePassword (e) {
        e.preventDefault()
        setShowPassword(!showPassword)
    }


    // handele change
    function handleChange(e) {
        if(e.target.name === 'rememberMe') {
            setState({
                ...formstate,
                [e.target.name]: parseInt(e.target.value)
            })
        }
        else {
            setState({
                ...formstate,
                [e.target.name]: e.target.value
            })
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        loginUser(formstate).then((response) => {
            console.log('Login Check',response)
            if (response.status == statusSuccess) {
                dispatch({type: 'login', payload: response.data.token});
                history.push('/');
            } else {
                setErrorState(response.message)
            }
        })
    }

    const responseGoogle =  (response) => {
        console.log('Google login ', response);
        let tokenId = response.credential;
        let metaData = { 'token': tokenId };
        authGoogle(metaData).then((response) => {
            console.log('Google Check', response);
            if (response.status !== statusFailure) {
                
                    dispatch({type: 'login', payload: response.data.token});
                    history.push('/');
                
              
            } else {
                // setErrorState(response.message)
            }
        })
    }

    const responseFacebook = (response) => {
        console.log('Facebook login ', response);
        let { name, email, mobile, picture, accessToken } = response;
        let metaData = { 'name': name, 'email': (email) ? email : '', 'mobile': (mobile) ? mobile : '', 'picture': (picture) ? picture : '', 'token': accessToken };
        authFacebook(metaData).then((response) => {
            console.log('Facebook Check', response);
            if (response.status !== statusFailure) {
                if(response.message === 'User already exist') {
                    dispatch({type: 'login', payload: response.data.token});
                    history.push('/');
                }
                else{
                    dispatch({type: 'login', payload: response.data.token});
                    history.push('/update-profile');
                }
            } else {
                // setErrorState(response.message)
            }
        })
    }

    useEffect(() => {
        getCountries().then(response => {
            if (response.status === Constant.statusSuccess) {
                setAllCountryCodes(response.data);
            } 
            else {
                setErrorState(response.message);
            }
        });
    }, []);

    let countryCodeOptions = [];
    if(allCountryCodes.length > 0) {
        countryCodeOptions = allCountryCodes.map((item, index) => {
            return <option key={index} value={item.phonecode}>{item.phonecode}</option>
        });
    }

    return (
      <>
        <div
          className="login_page1"
          style={{ background: `url(${bannerImage})` }}
        >
          <div className="innerdiv-login">
            <div className="div2 container">
              <div className=" flex flex-col justify-content-center align-items-center">
                <center>
                  <p className="event-p3">Welcome!</p>
                  <p className="event-p2 mb-4">We’re so excited to see you!</p>
                </center>
                {privacyChecked && checked ? (
                  <img
                    className="mt-5 mb-2 googlimg"
                    height={"50px"}
                    width={"50px"}
                    src={googleimg}
                  />
                ) : (
                  <div></div>
                )}
              
                    {privacyChecked && checked ? (
                      <GoogleOAuthProvider clientId="451080537233-a0gdg7j86p0valvd0vbs161ebeqgq0lt.apps.googleusercontent.com">
                        <GoogleLogin
                          render={(renderProps) => (
                            <button
                              type="button"
                              className=""
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                            >
                              <FcGoogle className="" /> Sign in with google
                            </button>
                          )}
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy="single_host_origin"
                        />
                      </GoogleOAuthProvider>
                    ) : (
                      <div></div>
                    )}

                    <label>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />{" "}
                      <Link to={"/term-condition"} className="text-blue mr-2">
                        I agree to the terms And conditions
                      </Link>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={privacyChecked}
                        onChange={() => setprivacyChecked(!privacyChecked)}
                      />{" "}
                      <Link to={"/privacy-policy"} className="text-blue mr-2">
                        I agree to the privacy policy
                      </Link>
                    </label>
                  </div>

                  {/* <FacebookLogin
                                    appId="141808665260060"
                                    callback={responseFacebook}
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn"><img src="/images/google.png" alt=""/> Log in with Google</button>
                                    )}
                                /> */}
           
            </div>
          </div>
        </div>
      </>
    );

}

