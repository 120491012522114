import $ from "jquery";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Context from "../Context";
import firstimg from '../Images/1.png';
import storybf from '../Images/Stories.png';
import book from "../Images/book.png";
import Layout from "../Layout/Layout";
import '../css/Stories.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { statusFailure } from "./Constant";
import { getAllContestData, getStories, getStoryCategories, userLike } from "./utilities/ApiCalls";
let multi = true;

export default function Stories(props) {
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const query = new URLSearchParams(props.location.search);
  let search = query.get("search");

  const { t } = useTranslation();

  let contestId = query.get("contest");
  let userId = query.get("userId");

  const [stories, setStories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [length, setLength] = useState(1);
  const [isLike, setLike] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getNewData, setNewData] = useState(0);

  useEffect(() => {
    let prams = { moduleType: 1 };
    getStoryCategories(prams).then((response) => {
      setCategories(response.data);
    });
  }, []);

  useEffect(() => {
    let prams = {};
    if (categoryId) prams.categoryId = categoryId;
    if (search) prams.searchKey = search;
    prams.pageNo = pageNo;
    if (contestId) prams.contestId = contestId;
    if (userId) prams.userId = userId;
    let userDetails = state.user;
    if (userDetails) {
      prams.user_id = userDetails._id;
    }
    console.log(prams, "are prams")
    if (!contestId){
    getStories(prams).then((response) => {
      if (response.data.length > 0) {
        if (getNewData) {
          setStories(response.data);
        } else {
          setStories([...stories, ...response.data]);
        }
        // setPageNo(response.pageNo);
        setLength(length+response.length);
        setNewData(0);
      } else {
        // setStories([]);
        setSuccessMessage(response.message);
      }
    });}else{
      // There is contest id so get all stories of contest
      getAllContestData(true, prams).then((response) => {
        console.log(response.data.data)
        if (response.data.length > 0) {
          if (getNewData) {
            // setStories(
            //   ...stories,
            //   response.data.data.filter((obj) => obj.contestId === contestId)
            // ); 
          } else {
            setStories([...stories,
              ...response.data.data.filter((obj) => obj.contestId === contestId)
            ]);
          }
      
          setLength(length+response.length);
          setNewData(0);
        } else {
          
          setSuccessMessage(response.message);
        }
      });
    }

    // if (categoryId) prams.categoryId = categoryId;
    // getStories(prams).then((response) => {
    //     setStories(response.data);
    // })
  }, [categoryId, pageNo]);

  function setCategory(e) {
    e.preventDefault();
    setCategoryId(e.target.value);
    setNewData(1);
    setPageNo(1);
    setLength(1);
  }

  function like(metaData) {
    let likeRequest = {
      moduleId: metaData.id,
      type: 1,
      user_id: "",
      title: metaData.title,
      description: metaData.description,
      thumbnail: metaData.thumbnail,
    };
    let val = parseInt($("#update_likes" + metaData.id).text());

    userLike(likeRequest).then((response) => {
      if (response.status !== statusFailure && response.data) {
        setLike(response.data.userLike);
        if (response.data.userLike) {
          $("#update_likes" + metaData.id).text(val + 1);
        } else {
          $("#update_likes" + metaData.id).text(val - 1);
        }
        if (
          $("#like_" + metaData.id).hasClass(
            "fa fa-thumbs-up default-cursor-point"
          )
        ) {
          $("#like_" + metaData.id).removeClass(
            "fa fa-thumbs-up default-cursor-point"
          );
          $("#like_" + metaData.id).addClass(
            "fa fa-thumbs-o-up default-cursor-point"
          );
        } else {
          $("#like_" + metaData.id).removeClass(
            "fa fa-thumbs-o-up default-cursor-point"
          );
          $("#like_" + metaData.id).addClass(
            "fa fa-thumbs-up default-cursor-point"
          );
        }
      }
    });
  }

  function fetchMoreData() {
    console.log('fetchMoreData')
    let nextPage = pageNo + 1;
  
      multi = false;
      console.log('going to '+nextPage.toString())
      setPageNo(nextPage);
    
    
  }

  let storiesList = [];
  if (stories.length > 0) {
    storiesList = stories.map((story, index) => {
      let str = story.description;
      let description = str;
      if (str && str.length > 60) {
        description = str.substring(0, 60) + "...";
      }

      let metaData = {
        id: story._id,
        title: story.name,
        description: story.description,
        thumbnail: story.thumbnail,
      };

      return (
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center custom-no-padding" key={story._id}>
         {(!sessionStorage.getItem('loginDetails') ? 
                                                            <div className="gallery-container" style={{ width: "100%", background: `url(${firstimg})` }}>
                                                                <div className="gallery_info">
                                                                    {/* <h2>{el.name}</h2> */}
                                                                    {/* <p>{description}</p> */}
                                                                </div>
                                                            </div> : 
                                                            <div className="" style={{ width: "95%", background: `url(${firstimg})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", height: "20em" }}>
                                                                <div className="gallery-container">
                                                                <Link to={`/story-details/` + story._id}>
                                                                    <div className="gallery_info">
                                                                        {/* <h2>{el.name}</h2>
                                                                        <p>{description}</p> */}
                                                                        <div className="hover-content">
                                                                        <p className="h1-div">{story.name}</p>
                                                                            <button className="extended-btn ">View Details</button>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Link>
                                                                </div>
                                                            </div>
                                                        )}  {(!sessionStorage.getItem('loginDetails') ? 
                                                        <div className="gallery-container" style={{ width: "100%", background: `url(${firstimg})` }}>
                                                            <div className="gallery_info">
                                                                {/* <h2>{el.name}</h2>
                                                                <p>{description}</p> */}
                                                            </div>
                                                        </div> : 
                                                        <div className="" style={{ width: "95%", background: `url(${firstimg})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", height: "20em" }}>
                                                            <div className="gallery-container">
                                                            <Link to={`/story-details/` + story._id}>
                                                                <div className="gallery_info">
                                                                    {/* <h2>{el.name}</h2>
                                                                    <p>{description}</p> */}
                                                                    <div className="hover-content">
                                                                    <p className="h1-div">{story.name}</p>
                                                                        <button className="extended-btn ">View Details</button>
                                                                       
                                                                    </div>
                                                                </div>
                                                                
                                                            </Link>
                                                            </div>
                                                        </div>
                                                    )}
          {/* <div class="overlay">
            <h2>{story.name}</h2>
            <p>{description}</p>
            <div class="theme-button1">
              {!sessionStorage.getItem("loginDetails") ? (
                <button
                  type=""
                  class="default-btn"
                  onClick={() => {
                    window.$("#homeReirectionModal").appendTo("body");
                    window.$("#homeReirectionModal").modal("show");
                  }}
                >
                  {t("stories.button.viewStory")}
                </button>
              ) : (
                // <button type="" class="default-btn" onClick={()=> { verifyUser(story._id, story.standard) }}>View Story</button>
                <Link to={"/story-details/" + story._id} class="default-btn">
                  {t("stories.button.viewStory")}
                </Link>
              )}
            </div>
            <div class="verif">
              {story.contestId ? (
                ""
              ) : story.status === 1 ? (
                <h6>
                  {t("stories.verified")} <i class="fa fa-check"></i>
                </h6>
              ) : (
                <h6>
                  {t("stories.notVerified")}
                  <i class="bi bi-stopwatch-fill"></i>
                </h6>
              )}
              <span>
                {t("stories.followers")} : {story.followers}
              </span>{" "}
              <span>
                <i
                  className={
                    story.userLike
                      ? "fa fa-thumbs-up default-cursor-point"
                      : "fa fa-thumbs-o-up default-cursor-point"
                  }
                  onClick={() => like(metaData)}
                  id={`like_${story._id}`}
                >
                  {" "}
                </i>{" "}
                <span id={`update_likes${story._id}`}>{story.likes}</span>
              </span>
              <span>Total Followers : {story.followers}</span>{" "}
              <span>
                {story.contestStatus === 1 || story.contestStatus === 3 ? (
                  <i
                    className={
                      story.userLike
                        ? "fa fa-thumbs-up default-cursor-point"
                        : "fa fa-thumbs-o-up default-cursor-point"
                    }
                    id={`like_${story._id}`}
                  >
                    {" "}
                  </i>
                ) : (
                  <i
                    className={
                      story.userLike
                        ? "fa fa-thumbs-up default-cursor-point"
                        : "fa fa-thumbs-o-up default-cursor-point"
                    }
                    onClick={() => like(metaData)}
                    id={`like_${story._id}`}
                  >
                    {" "}
                  </i>
                )}
                <span id={`update_likes${story._id}`}>{story.likes}</span>
              </span>
            </div>
          </div> */}
        </div>
      );
    });
  }

  return (
    <>
      <Layout>
        <div
          className="stories-maindiv"
          style={{ background: `url(${storybf})` }}
        >
          <div className="container">
            <div className="row ">
              <div
                className="col-lg-6 col-sm-12 col-md-6 col-12 justify-content-center align-items-center"
                style={{ marginTop: "15%" }}
              >
                <div className="Adventure-text">
                  Adventure <br></br>Begins...
                </div>
                <div className="mt-2">
                  <Link className="extended-btn" to={"/story-create"}>
                    {t("stories.button.writeStory")}
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-6 col-sm-12 col-md-6 col-12 justify-content-center"
                style={{ marginTop: "10%" }}
              >
                <img src={book} />
              </div>
            </div>
          </div>

          <div className="video_gallery_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                  <div className="tabs_div1">
                    <a
                      className="stories-btn"
                      value=""
                      onClick={setCategory}
                      href="#all"
                    >
                      {t("home.button.viewAll")}
                    </a>
                    <div>
                      <select
                        id="categoryDropdown"
                        value={categoryId}
                        onChange={setCategory}
                        className="stories-btn-dropdown "
                      >
                        <option value="">Generes</option>
                        {categories.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      {contestId ? (
                        ""
                      ) : (
                        <Link class="stories-btn3" to="/contest/1">
                          {t("stories.button.contest")}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <ul>
                      <InfiniteScroll
                        dataLength={stories.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                          <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                      >
                        {stories.map((story, index) => {
                          console.log(story)
                          return (
                            <div
                              className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center custom-no-padding mt-2"
                              key={story._id}
                            >
                              {!sessionStorage.getItem("loginDetails") ? (
                                <div
                                  className="gallery-container"
                                  style={{
                                    width: "90%",
                                    borderRadius: "60px",
                                    background: `url(${story.thumbnail})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    height: "20em",
                                  }}
                                >
                                  <div className="gallery_info">
                                    <h2>{story.name}</h2>
                                    <p>{story.description}</p>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className=""
                                  style={{
                                    width: "90%",
                                    borderRadius: "60px",
                                    background: `url(${story.thumbnail})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    height: "20em",
                                  }}
                                >
                                  <div className="gallery-container">
                                    <Link to={`/story-details/${story._id}`}>
                                      <div className="gallery_info">
                                        {/* <h2>{el.name}</h2>
                  <p>{description}</p> */}
                                        <div className="hover-content">
                                          <p className="h1-div">{story.name}</p>
                                          <p className="h1-div">
                                            {story.description}
                                          </p>
                                          <button className="extended-btn">
                                            View Details
                                          </button>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    </ul>
                    {successMessage && (
                      <div
                        className="alert alert-success success-msg-text"
                        role="alert"
                      >
                        {" "}
                        {successMessage}{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {}
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              
            </div>
          </div>
          <div
            class="modal fade"
            id="homeReirectionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content startPopup">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel1">
                    {t("stories.authRequired")}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>{t("stories.authMessage")}</p>
                  <div class="theme-button">
                    <Link to="/login" class="default-btn btn-sm">
                      {t("nav.login")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
