import Axios from "axios";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import { useContext, useEffect, useState } from "react";
import { registerPlugin } from "react-filepond";
import { useHistory } from "react-router";
import Context from "../Context";
import Layout from "../Layout/Layout";
import Constant, { statusFailure } from "../views/Constant";
import {
    createStory,
    getDataById,
    getStoryCategories,
    getThumbnailsFromAI,
    uploadThumbImage,
} from "../views/utilities/ApiCalls";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { getTokenDetails } from "../views/utilities/CommonFunction";

let val = 1;
let arr = [];

// register if want to preview

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


function ContestStory(props) {
    const query = new URLSearchParams(props.location.search);
    let constest_id = query.get("contest_id");
    let id = query.get("userId");
    let type = "story" //modified to story
    const { t } = useTranslation();
    let amount = query.get("amount");
  
    let token = sessionStorage.getItem("loginDetails");
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState("");
    const [documents, setDocuments] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [genBtnStatus, setgenBtnStatus] = useState(false);
    const [imgdata, setImgData] = useState("");
    const [thumblain, setThumblain] = useState(null);
    const { state, dispatch } = useContext(Context);
    const [storyForm, setStoryForm] = useState({
      name: "",
      thumbnail: "",
      categoryId: [],
      description: "",
      document: [],
      duration: "",
      contestId: "",
      id: "",
      isAdultContent: false,
    });
    const [btnStatus, setbtnStatus] = useState(false);
    const [images, setImages] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [length, setLength] = useState(1);
    const [retry, setRetry] = useState(0);
    const [contentData, setContentData] = useState({});
  
    useEffect(() => {
      // hide second step
      $("#second-step").hide();
      let prams = {};
      if (constest_id) prams.contestType = 1;
  
      if (constest_id) {
        setStoryForm({
          ...storyForm,
          contestId: constest_id,
        });
      }
  
      getStoryCategories(prams).then((response) => {
        if (response.status !== statusFailure) {
          setCategories(response.data);
        } else {
          // setError(response.message);
        }
      });
  
      getTHumbnail(1);

      if (id && type) {
        prams = { id: id, type: type };
        getDataById(prams).then((data) => {
          if (data.status === Constant.statusSuccess) {
            setContentData(data.data[0]);
  
            // make category active
  
            if (data.data[0].categoryId.length > 0) {
              let categoryIds = data.data[0].categoryId;
              categoryIds.map((el) => {
                $("#" + el).addClass("active");
              });
            }
  
            setStoryForm({
              ...storyForm,
              id: id,
              name: data.data[0].name,
              description: data.data[0].description,
              thumbnail: data.data[0].thumbnail,
              duration: data.data[0].duration,
              categoryId: data.data[0].categoryId,
              contestId: data.data[0].contestId,
              isAdultContent: data.data[0].isAdultContent,
            });
            console.log(storyForm,"HEREEE")
            console.log("test")
          } else {
            // setError(data.message);
          }
        });
      }
    }, []);
  
    useEffect(() => {
      let prams = {};
      if (constest_id) prams.contestType = 1;
  
      getStoryCategories(prams).then((response) => {
        if (response.status !== statusFailure) {
          setCategories(response.data);
        } else {
          // setError(response.message);
        }
      });
    }, []);
  
    useEffect(() => {
      console.log("stated changed", storyForm);
    }, [storyForm]);
  
    function onFormChange(e) {
      if (e.target.id === "isAdultContent") {
        console.log(e.target.checked);
        setStoryForm({
          ...storyForm,
          isAdultContent: e.target.checked,
        });
      } else {
        if (e.target.id === "name") {
          let words = e.target.value.split(" ").length;
          if (words > 5) {
            setError("Title should be less then or equal to 5 words.");
            setbtnStatus(true);
          } else {
            setError("");
            setbtnStatus(false);
          }
        }
  
        if (e.target.id === "description") {
          let words = e.target.value.split(" ").length;
          if (words > 5) {
            setError("Description should be less then or equal to 5 words.");
            setbtnStatus(true);
          } else {
            setError("");
            setbtnStatus(false);
          }
        }
  
        setStoryForm({
          ...storyForm,
          [e.target.name]: e.target.value,
        });
      }
    }
  
    function updateCategory(id) {
      // e.preventDefault()
      if ($("#" + id).hasClass("active")) {
        val -= 1;
        $("#" + id).removeClass("active");
        $("#" + id + "_check").removeClass("icofont-plus");
        $("#" + id + "_check").addClass("icofont-check");
        var index = arr
          .map((x) => {
            return x;
          })
          .indexOf(id);
        arr.splice(index, 1);
      } else {
        val += 1;
        $("#" + id).addClass("active");
        $("#" + id + "_check").removeClass("icofont-check");
        $("#" + id + "_check").addClass("icofont-plus");
        arr.push(id);
      }
  
      setStoryForm({
        ...storyForm,
        categoryId: arr,
      });
    }
  
    async function getTHumbnail(page) {
      let token = sessionStorage.getItem("loginDetails");
      let response = await Axios.get(
        Constant.apiBasePath + "content-thumbnail/getDataForUser?pageNo=" + page,
        {
          headers: { token: token },
        }
      );
      if (response.status !== "Success") {
        console.log("asassaa");
        setImages(response.data.data);
        setPageNo(response.data.pageNo);
        setLength(response.data.length);
      } else {
        setError(response.message);
      }
    }
  
    function setThumbnail(thumbnail, id) {
      setStoryForm({
        ...storyForm,
        thumbnail: thumbnail,
      });
  
      $(".ticcircle").removeClass("active");
      $("#ticcircle_" + id).addClass("active");
    }
  
    async function submitStory(e) {
      e.preventDefault();
      setbtnStatus(true);
      let form = {}
      if (imgdata !== "") {
        let response = await uploadThumbImage(imgdata);
  
        form = storyForm;
        form["thumbnail"] = response.location;
      } else {
        form = storyForm;
      }
      console.log(form)
      createStory(form).then((response) => {
        if (response.status !== statusFailure && response.data != "") {
          let token = sessionStorage.getItem("loginDetails");
          let userDetails = {};
          if (token) {
            userDetails = getTokenDetails(token);
          }
  
            if (id && type) {
              setSuccessMessage(response.message);
              if (userDetails) {
                setSuccessMessage(response.message);
                setTimeout(function () {
                  history.push(`/profile/${userDetails._id}`);
                }, 3000);
              }
            } else {
              if (response.isAccess === false) {
                setSuccessMessage(response.message);
                setTimeout(function () {
                  history.push("/plans");
                }, 3000);
              } else {
                setSuccessMessage(response.message);
                setTimeout(function () {
                  history.push("/stories");
                }, 3000);
              }
          }
        } else {
          setbtnStatus(false);
          setError(response.message);
        }
      });
    }
  
    function uploadDocuments(response) {
      response = JSON.parse(response);
      if (response.status !== statusFailure) {
        setStoryForm({
          ...storyForm,
          document: [...storyForm.document, response.location],
        });
      } else {
        setError(response.message);
      }
    }
  
    function previousPage(page) {
      setPageNo(page);
    }
  
    function nextPage(page) {
      setPageNo(page);
    }
  
    var previous = 0;
    var next = 0;
    var customLength = 0;
  
    if (pageNo >= 0) {
      previous = pageNo - 1;
      next = pageNo + 1;
    }
  
    if (length !== 0) {
      customLength = length;
    }
  
    function handleModel(type) {
      if (type === 1) {
        window.$("#contentThumbnail").modal("show");
      } else {
        window.$("#contentThumbnail").modal("hide");
      }
    }
  
    function uploadThumbnail() {
      document.getElementById("file-image").classList.remove("hidden");
      document.getElementById("file-image").src = storyForm.thumbnail;
      window.$("#contentThumbnail").modal("hide");
    }
  
    if (contentData.name !== undefined) {
      document.getElementById("file-image").classList.remove("hidden");
      document.getElementById("file-image").src = contentData.thumbnail;
    }
    async function getAIgeneratedImage(e) {
      if (retry < 3) {
        e.preventDefault();
        setgenBtnStatus(true);
        var data = await getThumbnailsFromAI(
          storyForm.name,
          storyForm.description
        );
        setImgData(data[1]);
        setRetry(retry + 1);
        console.log(retry);
        document.getElementById("file-image").classList.remove("hidden");
        document.getElementById("file-image").src = data[0];
        e.preventDefault();
        setgenBtnStatus(false);
      } else {
        setError(
          "You have exceeded AI Thumbnail generation limit which is 3 images"
        );
      }
    }
    function validatePreviewData() {
      if (storyForm.name === "") {
        setbtnStatus(true);
        setError('"name" is not allowed to be empty');
      } else if (storyForm.thumbnail === "") {
        setbtnStatus(true);
        setError('"thumbnail" is not allowed to be empty');
      } else if (storyForm.categoryId.length < 1) {
        setbtnStatus(true);
        setError('"categoryId" does not contain 1 required value(s)');
      } else if (storyForm.duration === "") {
        setbtnStatus(true);
        setError('"duration" is not allowed to be empty');
      } else {
        setError("");
        setbtnStatus(false);
        $("#first-step").hide();
        $("#second-step").show();
      }
    }
  
    function hideStepTwo() {
      $("#second-step").hide();
      $("#first-step").show();
    }
  
    return (
      <Layout>
        <div style={{ background: "#07262B", minHeight: "100vh" }}>
          <div className="container p-3 mt-5">
            <div className="row" style={{ marginTop: "10%" }}>
              <div className="col-sm-12 col-md-6">
                <label className="write-story-header">
                  Enter story title
                  <span style={{ background: "#B20101 !important" }}>
                    *
                  </span>{" "}
                  <span className="helper-text">(Max 5 Words)</span>
                </label>
                <input
                  type="text"
                  className="input-ele form-control"
                  placeholder="Story Name"
                  onChange={onFormChange}
                  name="name"
                  id="name"
                  defaultValue={storyForm.name}
                  required
                />
              </div>

              <div className="col-sm-12 col-md-3">
                <label className="write-story-header">
                  Category
                  <span style={{ background: "#B20101 !important" }}>*</span>
                </label>
                <select
                  className="helper- text form-control"
                  onChange={(e) => {
                    updateCategory(e.target.value);
                  }}
                  name="category"
                >
                  <option value="" selected>
                    {" "}
                    Category
                  </option>
                  {categories.map((category, index) => {
                    return (
                      <option key={category._id} value={category._id}>
                        {" "}
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-12 col-md-3">
                <label className="write-story-header">
                  Duration <span className="helper-text">(Min)</span>
                </label>
                <input
                  type="Number"
                  className="input-ele form-control"
                  placeholder="duration of story"
                  onChange={onFormChange}
                  name="duration"
                  defaultValue={storyForm.duration}
                  required
                />
              </div>
            </div>

            <div className="row mt-5 ">
              <div className="col-lg-4 col-sm-12 col-md-4 col-12 mt-5 mb-2">
                <div className="top_categories_box">
                  <form
                    id="file-upload-form"
                    className="uploader"
                    onClick={getAIgeneratedImage}
                  >
                    <input
                      id="file-upload"
                      type="hidden"
                      onChange=""
                      name="thumbnail"
                      accept="image/*"
                      onClick={getAIgeneratedImage}
                    />
                    <label htmlFor="file-upload" id="file-drag">
                      <img
                        id="file-image"
                        src="/images/download-image.png"
                        alt="Preview"
                        className="hidden"
                      />
                      <div id="start">
                        {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                        <div className="h2-div">
                          {/* <h2 style={{ color: "white" }}>
                            {t("stories.createStory.uploadText")}
                          </h2>{" "}
                          OR{" "} */}
                          <h2
                            onClick={getAIgeneratedImage}
                            disabled={genBtnStatus}
                            style={{ color: "white" }}
                          >
                            {" "}
                            {genBtnStatus ? (
                              <div className="content-loader">
                                <img src="images/loader.gif" height={20} />
                              </div>
                            ) : (
                              "Generate Thumbnail using AI"
                            )}
                          </h2>
                        </div>
                        <div id="notimage" className="hidden">
                          Please select an image
                        </div>
                      </div>
                      <div id="response" className="hidden">
                        <div id="messages"></div>
                        <progress
                          className="progress"
                          id="file-progress"
                          value="0"
                        >
                          <span>100%</span>%
                        </progress>
                      </div>
                    </label>
                  </form>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12 col-md-8 col-12">
                <label
                  className="write-story-header"
                  style={{ fontSize: "40px" }}
                >
                  Enter story
                  <span style={{ background: "#B20101 !important" }}>
                    *
                  </span>{" "}
                  <span className="helper-text">(Max 5 Words)</span>
                </label>
                <textarea
                  rows={20}
                  type="text"
                  className="story-area form-control"
                  placeholder="Start writing"
                  onChange={onFormChange}
                  name="description"
                  id="description"
                  defaultValue={storyForm.description}
                />
              </div>
            </div>
            {error !== "" ? (
              <div className="alert alert-danger error-msg-text" role="alert">
                {" "}
                {error}{" "}
              </div>
            ) : (
              <div></div>
            )}
            {successMessage && (
              <div
                className="alert alert-success success-msg-text"
                role="alert"
              >
                {" "}
                {successMessage}{" "}
              </div>
            )}
            <div className="d-flex">
              <div className="theme-button1 d-inline mt-3">
                <button
                  type="button"
                  className="extended-btn"
                  onClick={submitStory}
                  disabled={btnStatus}
                >
                  {amount ? `Pay now` : t("stories.createStory.button.submit")}
                  {btnStatus ? (
                    <div className="content-loader">
                      <img
                        src="images/loader.gif"
                        height={30}
                        style={{ backgroundColor: "#c81919" }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
            <center></center>
          </div>
        </div>{" "}
      </Layout>
    );
}

export default ContestStory;