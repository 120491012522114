import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link } from 'react-router-dom';
import Context from "../../Context";
import narattorImg from "../../Images/narattorImg.png";
import Layout from "../../Layout/Layout";
import "../../css/Stories.css";
import { statusFailure } from '../../views/Constant';
import { getStories, userFollow, userLike } from "../utilities/ApiCalls";
import { getTokenDetails } from "../utilities/CommonFunction";
function StroyDetailsNew(props) {
    let { id } = useParams();

    const [story, setStory] = useState({});
    const [isLike, setLike] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorState, setErrorState] = useState(null);
    const [similarStory, setSimilarStory] = useState([]);
    const history = useHistory();
    const {state, dispatch} = useContext(Context);
    const [similarId, setSimilarId] = useState('');
    const [userId, setUserId] = useState('');
    const [userBadge, setUserBadge] = useState('');
    const [isAccess, setIsAccess] = useState(true);
    const [isFollow, setFollow] = useState(false);
    const {t} = useTranslation();


    useEffect(() => {
        let prams = {
            id:id
        };
        let token = sessionStorage.getItem('loginDetails');
        // get data by similar id

        if(similarId) {
            prams = {
                id: similarId
            }
        }
        if(token) {
            let userDetails = getTokenDetails(token);
            if(userDetails) {
                prams.user_id = userDetails._id;
                setUserId(userDetails._id);
            }
        }
        
        if(state.user.userBadge) {
            setUserBadge(state.user.userBadge.title);    
        }

        getStories(prams).then((response) => {
            if (response.status !== statusFailure) {
                setStory(response.data[0]);                
                setSimilarStory(response.similarStory);
                setIsAccess(response.isAccess);

                if(response.isAccess === false || response.isBadge === true) {
                    setSuccessMessage(response.message);
                }
            } else {
                setErrorState(response.message);
                setTimeout(function() {
                    setErrorState('');
                }, 3000);
            }
        })
    }, [isLike, similarId, isFollow]);

    function like() {
        let likeRequest = {
            "moduleId": story._id,
            "type": 1,
            "user_id": '',
            "title": story.name,
            "description": story.description,
            "thumbnail": story.thumbnail
        }

        userLike(likeRequest).then(response => {
            if (response.status !== statusFailure && response.data) {
               setLike(response.data.userLike);
            }
        });
    }

    function verifyUser(id, standard) {
        setSimilarId(id);
        if(standard === 2) {
            if(state.user.isPrime) {
                history.push('/story-details/' + id);
            }
            else {
                // history.push('/plans');
                history.push('/story-details/' + id);
            }
        }
        else{
            history.push('/story-details/' + id);
        }
    }

    function follow(followingId, status) {
        let userStatus = (status) ? 0 : 1;
        let userRequest = {
            "followingId": followingId,
            "status": userStatus
        }

        userFollow(userRequest).then(response => {
            if (response.status !== statusFailure) {
               setFollow(status);
            }
        });
    }
    return ( 
        <Layout>
        <div style={{background: '#07262B', minHeight: '100vh', paddingTop:'300px'}}>
            <div className="container">
            {/* {errorState && <div className="alert alert-error error-msg-text" role="alert"> {errorState} </div>}
                    {successMessage && <div className="alert alert-success success-msg-text" role="alert"> {successMessage} </div>} */}
                <div className="row p-5" style={{background: '#0C343A', maxHeight: '144vh'}}>
                    <div className="row p-5" style={{position: 'relative', top: '-360px'}}>
                        <div className="row" style={{marginTop:"10%"}}>
                            <div className="col-sm-12 col-md-4">
                                <img className="shadow-icon"   src={story.thumbnail} alt="" style={{ pointerEvents: 'none' }} />
                            </div>
                            <div className="col-sm-12 col-md-8 d-flex" style={{flexDirection: 'column', justifyContent: 'center'}}>
                                <div className="story-name">{story.name}</div>
                                <div className="story-writer">{ story.userName }</div>
                            </div>
                        </div> 
                        <div className="row " style={{marginTop: '10px', width:"100%"}}>
                            <div className="row1">                           
                                 <div className=" ">
                            { (isAccess) ? 
                            <p className="story-main">{story.description}</p>
                            : <Link to='/plans' class="extended-btn">Buy plan</Link>}
                            </div>
                            <div className=" ">
                                <div className="row d-flex " style={{flexDirection: 'column'}}>
                                   { (story.createdAt) 
                                    ?  <> <div className="row" style={{justifyContent: 'end'}}><span className="descriptions">{t('stories.createStory.duration') }:</span><span className="duration-min"> {(story.duration) ? story.duration : '0 min' }</span></div>
                                    <div className="row" style={{justifyContent: 'end'}}> <span className="descriptions">{ t('date')}: </span><span className="duration-min"> {story.createdAt.split('T')[0] }</span></div></> :
                                    ''}
                                </div>
                                <div className="row  mt-5"style={{justifyContent: 'end'}}>
                                    <div className="p-2">
                                        <img src={narattorImg} alt=''/>
                                    </div>
                                    <div className="">
                                    <div >  <Link className="userName" to={`/profile/${story.userId}`}> { story.userName }</Link>
                                        <div className="story-main">{t('stories.followers')} : { story.followers }</div>
                                        <div className="story-main">{t('stories.badge')} : { userBadge } </div>
                                    {  (story.userId === userId)
                                            ?
                                            ''
                                            :   <button className="follow-btn mt-2" onClick={()=> { follow(story.userId, story.userFollow) }}>{ (story.userFollow) ? t('stories.unfollow') : t('stories.follow') }</button>}
                                    </div>
                                </div>
                            </div>
                            </div>

                        </div>
                        <div className="m-2" style={{display: 'flex', justifyContent: 'space-between', width: "100%"}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                            <span>
                                        
                                           
                                        <i className= { (isLike || story.userLike) ? 'fa fa-thumbs-up default-cursor-point' : 'fa fa-thumbs-o-up default-cursor-point' } onClick={ like }> </i>
                                        {story.likes }</span>
                                        {
                                        (story.userId === userId && !story.contestId)
                                        ?
                                            <span className="editforcreate"><Link to={`/update-story?type=1&id=${story._id}`}><i className="fa fa-edit"></i></Link></span>
                                        :
                                            ''
                                    }
                                    
                            </div>
                            <div>
                                <button className="reading-btn">Start Reading</button>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '50px'}}>
                     
                            <p className="story-main">{story.description}</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </Layout>
     );
}

export default StroyDetailsNew;