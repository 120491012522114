// Navbar.js

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Context from "../Context";

import { statusFailure } from "../views/Constant";
import { getProfileDetails } from "../views/utilities/ApiCalls";

export default function NavAuthenticated() {
    const { state, dispatch } = useContext(Context);
    const [profile, setProfile] = useState({});
    const location = useLocation();
    const [error, setError] = useState(null);
    const history = useHistory();
    const [messageTitle, setMessageTitle] = useState('');
    const { i18n, t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    function logout(e) {
        e.preventDefault()
        // remove local storage
        dispatch({ type: 'logout', payload: null });
    }

    useEffect(() => {
        getProfileDetails().then((response) => {
            if (response.status !== statusFailure) {
                setProfile(response.data)
            } else {
                setError(response.message);
            }
        });
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.navbar');
            if (navbar) {
                if (window.scrollY > 100) {
                    navbar.classList.add('navbar-scrolled');
                } else {
                    navbar.classList.remove('navbar-scrolled');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function verifyUserAccess(moduleType) {
        if (moduleType === 1) {
            if (profile.isPrime) {
                history.push('/audios');
            }
            else {
                window.$('#sidebarSubscriptionPlanModal').modal('show');
                setMessageTitle('audio');
            }
        }
        else if (moduleType === 2) {
            if (profile.isPrime) {
                history.push('/videos');
            }
            else {
                window.$('#sidebarSubscriptionPlanModal').modal('show');
                setMessageTitle('video');
            }
        }
        else if (moduleType === 3) {
            if (profile.isPrime) {
                history.push('/movies');
            }
            else {
                window.$('#sidebarSubscriptionPlanModal').modal('show');
                setMessageTitle('movie');
            }
        }
    }

    function closeModelAndRedirect() {
        window.$('#sidebarSubscriptionPlanModal').modal('hide');
        history.push('/plans');
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent px-5 p-0">
            <div className="container p-0">
                <Link className="navbar-brand logo-background1" to={'/'}>
                   
                        <img src="/images/logo.png" alt="logo" />
                       
                       
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setExpanded(!expanded)}
                >
                    <span className=" " style={{color:"#fff"}}>|||</span>
                </button>

                <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav ml-auto nav-item-background ">
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                {t('nav.more')}<span ></span>
                            </a>
                            <div className="dropdown-menu">
                                <Link to={`/profile/${profile._id}`} className="dropdown-item">{t('nav.profile')}</Link>
                                <Link to={'/about-us'} className="dropdown-item">{t('nav.aboutUs')}</Link>
                                <Link to={'/faqs'} className="dropdown-item">{t('nav.faq')}</Link>
                            </div>
                        </li> */}
                        <li className="nav-item">
                                     <Link to={'/about-us'} className="nav-link">{t('nav.aboutUs')}</Link>
                            </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                        {t('nav.language')}<span ></span>
                            </a>
                            <div className="dropdown-menu" style={{ width: "100%" }}>
                                <a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage('en')}>English</a>
                                <a className="dropdown-item" href="#" onClick={() => i18n.changeLanguage('hi')}>हिन्दी</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                            <img src={profile.profilePic} width={30} height={30} alt="" /><span > {profile.name}</span>
                            </a>
                            <div className="dropdown-menu" style={{ width: "100%" }}>
                            <Link to='/profile' className="dropdown-item">
                                        <i className="fa fa-user-o"></i>Profile
                                    </Link>
                          
                                    <a href="#" className="dropdown-item" onClick={logout}>
                                        <i className="fa fa-power-off"></i> {t('nav.logout')}
                                    </a>
                            </div>
                        </li>
                    </ul>

                    <div className="user_icon">
                        <a data-toggle="modal" data-target="#search-modal" href="#">
                            <i className="icofont-search"></i>
                        </a>

                        {/* <div className="user">
                            <div className="nav-item dropdown">
                                <img src={profile.profilePic} width={30} height={30} alt="" />
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                   
                                </a>
                                <div className="dropdown-menu">
                                    <Link to='/update-password' className="dropdown-item">
                                        <i className="fa fa-user-o"></i>{t('nav.updatePassword')}
                                    </Link>
                                    <a href="#" className="dropdown-item" onClick={logout}>
                                        <i className="fa fa-power-off"></i> {t('nav.logout')}
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </nav>
    );
}
