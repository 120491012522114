import {useEffect, useState, useContext} from "react";
import Context from "../Context";
import Constant from "./Constant";
import { createAppPaymentRequest, updateAppPaymentDetails } from './utilities/ApiCalls';
import { getTokenDetails } from "./utilities/CommonFunction";

export default function Payment(props) {
    const [successState, setSuccessState] = useState(null);
    const [errorState, setErrorState] = useState(null);

    const query = new URLSearchParams(props.location.search);
    let user_id = query.get('user_id');
    let user_name = query.get('user_name');
    let mobile = query.get('mobile');
    let email = query.get('email');
    let enrollment_id = query.get('enrollment_id');
    let amount = query.get('amount');
    let content_type = query.get('content_type');

    let payment_id = query.get('payment_id');
    let payment_status = query.get('payment_status');
    let payment_request_id = query.get('payment_request_id');
    let payment_for = query.get('payment_for');

    useEffect(() => {

        // update payment details in db
        if(payment_id && payment_status && payment_request_id) {
            let paymentRequest = {
                "userId": user_id,
                "orderId": payment_request_id,
                "paymentId": payment_id,
                "paymentStatus": 2,
                "isPrime": 1
            }
            updateAppPaymentDetails(paymentRequest).then(response => {
                if(response.status === Constant.statusSuccess) {
                    setSuccessState(response.message);
                    // document.title = 'Payment details updated';
                    window.location.href = `${Constant.domain}` + `online-payment?status=` + 2;
                    // setTimeout(function() {
                    //     history.push(`/profile/${params.userId}`);
                    // }, 3000);
                }
                else {
                    setErrorState(response.message);
                }
            }).catch(error => {
                console.log(error.response.data.message);
            })
        }
        else {
            // create payment order on load the page

            let paymentRequest = {
                "userId": user_id,
                "userName": user_name,
                "mobile": mobile,
                "email": email,
                "amount": parseInt(amount),
                "purpose": content_type,
                "redirectURL": `${Constant.domain}` + `online-payment?user_id=` + user_id,
                "isPrime": 1,
                "paymentFor": payment_for,
                "enrollmentId": enrollment_id
            }

            // call api

            createAppPaymentRequest(paymentRequest).then(orderResponse => {
                if(orderResponse.status === Constant.statusSuccess) {
                    window.location.href = orderResponse.data.longurl;
                }
                else {
                    setErrorState(orderResponse.message);
                }
            }).catch(error => {
                console.log(error.response.data.message);
            });
        }

    }, []);

    return <>
        <section className="top_categories_section" id="first-step">
            <div className="container">
                {
                    (successState)
                    ?
                    <p className="text-success">{successState}</p>
                    :
                    ""
                }
                {
                    (errorState)
                    ?
                    <p className="text-danger">{errorState}</p>
                    :
                    ""
                }
                {/* <button className="btn btn-primary" onClick={createRequest}>Create Order</button> */}
            </div>
        </section>
    </>
}